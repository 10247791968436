<template>
  <div class="backMenu">
    <div class="">
      <el-card class="box-card">
        <!-- 搜索新建 -->
        <div class="MenuTop">
          <div class="btn">
            <el-button type="primary" @click="addMenuDigo">新建菜单</el-button>
          </div>
        </div>
        <!-- 菜单列表 -->
        <div class="MenuList scorr-roll">
          <el-table
            :data="MenuList.data"
            row-key="id"
            :default-expand-all="isExpand"
            border
            style="width: 100%; margin-bottom: 20px"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            >
            <el-table-column
              prop="name"
              label="菜单名称"
              min-width="120"
              align="left"
            >
            </el-table-column>
            <el-table-column
              prop="type"
              label="菜单类型"
              min-width="100"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.type == 1 ? "菜单" : row.type == 2 ? "按钮" : "列表" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="icon" label="图标" align="center">
              <template slot-scope="{ row }">
                <div class="exceed">
                  <i :class="row.icon" class="iconfont"></i>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="tagName"
              label="菜单标识"
              min-width="150"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.tagName }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="permissions"
              min-width="280"
              label="接口权限"
              align="left"
            >
              <template slot-scope="{ row }">
                <div v-for="(item, index) in row.permissions" :key="index">
                  <span>{{ item }}</span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="sort"
              label="排序"
              min-width="60"
              align="center"
              sortable
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.sort }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="type"
              label="是否隐藏"
              min-width="60"
              align="center"
            >
              <template slot-scope="{ row }">
                <el-switch
                  v-model="row.hidden"
                  :active-value="1"
                  :inactive-value="0"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="changeSwitch(row.hidden, row)"
                />
              </template>
            </el-table-column>

            <el-table-column
              prop="address"
              label="操作"
              width="160"
              align="center"
            >
              <template slot-scope="{ row }">
                <div class="rootBtn">
                  <button @click="sonBtn(row)">子菜单</button>
                  <button @click="EditBtn(row)">编辑</button>
                  <button @click="toBtn(row)">删除</button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>

        <!-- 新增弹框 -->
        <div>
          <el-dialog
            title="菜单"
            :visible.sync="isAdd"
            width="700px"
            :close-on-click-modal="false"
            :close-on-press-escape="false"
          >
            <div class="AddMenu scorr-roll">
              <div class="AddMenu-1-box">
                <div>
                  <el-form label-width="120px">
                    <el-form-item label="上级:">
                      <el-select
                        style="width: 400px"
                        v-model="parentName"
                        placeholder="请选择上级"
                      >
                        <el-option
                          style="height: 100%; padding: 0"
                          :value="addData.parentId"
                        >
                          <el-tree
                            ref="tree"
                            :data="stairOptionsData"
                            :props="propsData"
                            empty-text="无匹配数据"
                            node-key="id"
                            @node-click="pidChang"
                          />
                        </el-option>
                      </el-select>
                    </el-form-item>

                    <el-form-item label="菜单名称:" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.name"
                          placeholder="请输入菜单名称"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="菜单类型:" required>
                      <el-radio
                        v-model="addData.type"
                        v-for="(item, index) in stairOptions"
                        :key="index"
                        :label="item.type"
                        border
                        >{{ item.name }}</el-radio
                      >
                    </el-form-item>
                    <el-form-item label="菜单标识" required>
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.tagName"
                          placeholder="请输入菜单标识"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="icon图标:">
                      <div class="inputbox">
                        <el-select
                          v-model="addData.icon"
                          placeholder="请选择icon"
                          clearable
                        >
                          <div slot="prefix">
                            <i :class="addData.icon" class="iconfont"></i>
                          </div>
                          <el-option
                            v-for="item in iconList"
                            :key="item.value"
                            :label="item.value"
                            :value="item.value"
                          >
                            <i
                              :class="item.value"
                              class="iconfont"
                              style="float: left"
                            ></i>
                            <span style="float: right; font-size: 13px">{{
                              item.value
                            }}</span>
                          </el-option>
                        </el-select>
                      </div>
                    </el-form-item>

                    <el-form-item label="接口权限">
                      <div
                        class="inputbox more"
                        v-for="(items, index) in addData.permissions"
                        :class="
                          index == addData.permissions.length - 1 ||
                          addData.permissions.length == 0
                            ? 'nomb'
                            : ''
                        "
                        :key="index"
                      >
                        <el-input
                          v-model="addData.permissions[index]"
                          placeholder="请输入接口权限"
                        ></el-input>
                        <div>
                          <span
                            @click="closePermissions(index)"
                            v-if="index != 0"
                            >删除</span
                          ><span
                            v-if="
                              index == addData.permissions.length - 1 ||
                              addData.permissions.length == 0
                            "
                            @click="addPermissions()"
                            >添加</span
                          >
                        </div>
                      </div>
                    </el-form-item>
                    <el-form-item label="组件路径">
                      <div class="inputbox">
                        <el-input
                          v-model.trim="addData.url"
                          placeholder="请输入组件路径"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="排序">
                      <div>
                        <el-input
                          type="number"
                          v-model.trim="addData.sort"
                          placeholder="请输入排序"
                        ></el-input>
                      </div>
                    </el-form-item>
                    <el-form-item label="是否隐藏">
                      <div>
                        <el-switch
                          v-model="addData.hidden"
                          active-color="#13ce66"
                          inactive-color="#ff4949"
                          active-value="1"
                          inactive-value="0"
                        >
                        </el-switch>
                      </div>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="isAdd = false">取 消</el-button>
              <el-button type="primary" @click="AddMenu">确 定</el-button>
            </span>
          </el-dialog>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
import BtnJurisdiction from "@/utils/jurisdictionbtn"; //按钮权限公共方法
import { createNamespacedHelpers } from "vuex";
const { mapActions } = createNamespacedHelpers("power"); //vuex公共库
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
export default {
  data() {
    return {
      iconList: [
        {
          value: "SF-a-Group7",
        },
        {
          value: "SF-zhanghaoguanli",
        },
        {
          value: "SF-icon-test",
        },
        {
          value: "SF-yingxiao",
        },
        {
          value: "SF-huiyuanzhongxin1",
        },
        {
          value: "SF-dianpushezhi1",
        },
        {
          value: "SF-dingdanzhongxin",
        },
        {
          value: "SF-fenxiaozhongxin",
        },
        {
          value: "SF-zijinguanli",
        },
        {
          value: "SF-shangpinzhongxin",
        },
        {
          value: "SF-quanxianguanli",
        },
      ],
      propsData: {
        // value: "id",
        label: "name",
        children: "children",
        checkStrictly: true,
        //级联选择器配置项
      },
      eParentName: "",
      sonParentName: "",
      typeCee: "",
      MenuList: [], //菜单列表数据
      MenuApi: {
        keyWords: "",
        page: 1,
      }, //请求参数
      isAdd: false,

      edit: "",
      // 增加的数据
      addData: {
        id: "",
        parentId: "", //父级id
        name: "", //名称
        type: 1, //类型 1菜单 2按钮 3列表
        icon: "", //图标
        tagName: "", //
        url: "", //
        permissions: "",
        icon: "",
        sort: "",
        hidden: "", //是否隐藏 1-是 0-否
      },

      parentName: "",
      iDzs: [],
      son: "0",
      stairOptions: [
        {
          type: 1,
          name: "菜单",
        },
        {
          type: 2,
          name: "按钮",
        },
        {
          type: 3,
          name: "列表",
        },
      ],
      stairOptionsData: [],
      isExpand: false,
    };
  },
  computed: {
    ...commonIndex.mapState(["mainHright", "detailsSeach"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.MenuApi = this.detailsSeach[router];
    }
    this.RootMenuList();
  },
  methods: {
    ...mapActions([
      "getSupplierMenuList",
      "postSupplierMenuAdd",
      "postSupplierMenuEdit",
      "postSupplierMenuDel",
      "postSupplierMenuHidden",
    ]),
    addPermissions() {
      this.addData.permissions.push(null);
    },
    closePermissions(index) {
      this.addData.permissions.splice(index, 1);
    },

    // 建子菜单
    sonBtn(v) {
      this.addData.permissions = [null];
      this.parentName = v.name;
      this.addData = {
        id: "",
        parentId: "", //父级id
        name: "", //名称
        type: 1, //类型 1菜单 2按钮 3列表
        icon: "", //图标
        tagName: "", //
        url: "", //
        permissions: [],
        icon: "",
        sort: "",
        hidden: "", //是否隐藏 1-是 0-否
      };
      this.addData.parentId = v.id;

      // if (
      //   this.addData.permissions == null ||
      //   this.addData.permissions.length == 0
      // ) {
      //   this.addData.permissions = [];
      // }
      this.getMenuOptions();
      this.isAdd = true;
    },

    // 打开编辑弹框
    async EditBtn(data) {
      // this.addData = data;
      this.parentName = "";
      this.addData = JSON.parse(JSON.stringify(data));
      let res = await this.getSupplierMenuList();
      if (res.code == 200) {
        this.stairOptionsData = res.data;
        this.getData(this.stairOptionsData, this.addData.parentId);
      }
      if (
        this.addData.permissions == null ||
        this.addData.permissions.length == 0
      ) {
        this.addData.permissions = [null];
      }
      this.isAdd = true;
    },

    // 递归
    getData(data, id) {
      // console.log(data, id, "---递归---");
      data.forEach((v) => {
        if (v.id === id) {
          this.parentName = v.name;
          return;
        } else if (v.children && v.children.length) {
          this.getData(v.children, id);
        }
      });
    },
    // 新建菜单弹框
    addMenuDigo() {
      this.addData = {
        parentId: "", //父级id
        name: "", //名称
        type: 1, //类型 1菜单 2按钮 3列表
        icon: "", //图标
        tagName: "", //
        url: "", //
        permissions: [],
        icon: "",
        sort: "",
        hidden: "", //是否隐藏 1-是 0-否
      };
      this.parentName = "";
      this.getMenuOptions();
      if (
        this.addData.permissions == null ||
        this.addData.permissions.length == 0
      ) {
        this.addData.permissions = [null];
      }
      this.isAdd = true;
    },
    //
    pidChang(val) {
      if (val) {
        this.parentName = val.name;
        this.addData.parentId = val.id;
      }
    },

    //列表的开关接口
    async changeSwitch(type, row) {
      let res = await this.postSupplierMenuHidden({
        hidden: type,
        ids: [row.id],
      });
      if (res.code == "200") {
        this.$message({
          type: "success",
          message: res.message,
        });
        this.RootMenuList();
      } else {
        this.$message({
          type: "warning",
          message: res.message,
        });
      }
    },

    // 新建菜单
    async AddMenu() {
      // 判断是否填完必填项
      if (
        this.addData.name == "" ||
        this.addData.type == "" ||
        this.addData.tagName == ""
      ) {
        this.$message({
          message: "请将带星号的填写完成",
          type: "warning",
        });
        return;
      }
      this.addData.permissions = this.addData.permissions.filter(
        (item) => item != null && item != ""
      );

      // 发送新建请求
      let data = null;
      if (this.addData.id) {
        data = await this.postSupplierMenuEdit(this.addData);
      } else {
        data = await this.postSupplierMenuAdd(this.addData);
      }
      if (data.code == 200) {
        this.$message({
          type: "success",
          message: data.message,
        });
        this.RootMenuList();
        this.addData = {};
        this.parentName = "";
        this.isAdd = false;
      } else {
        this.$message({
          type: "warning",
          message: data.message,
        });
      }
    },

    // 获取一级菜单搜索
    async getMenuOptions() {
      let data = await this.getSupplierMenuList({ level: 1 });
      if (data.code == 200) {
        this.stairOptionsData = data.data;
        this.stairOptionsData.unshift({ name: "请选择菜单", id: 0 });
        if (
          this.addData.permissions == null ||
          this.addData.permissions.length == 0
        ) {
          this.addData.permissions = [null];
        }
      }
    },
    // 拿取权限列表里的菜单列表
    async RootMenuList() {
      let data = await this.getSupplierMenuList();
      if (data.code == 200) {
        this.MenuList = data;
      }
    },
    // 删除
    toBtn(item) {
      this.$confirm("此操作将删除该数据, 是否继续?", "删除", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.adelSplie(item);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    async adelSplie(item) {
      let data = await this.postSupplierMenuDel({
        ids: [item.id],
      });
      if (data.code == 200) {
        this.$message({
          type: "success",
          message: data.message,
        });
        this.RootMenuList();
      } else {
        this.$message({
          type: "success",
          message: data.message,
        });
      }
    },
  },
};
</script>
<style scoped>
.el-input__inner {
  line-height: 1px !important;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.expand .el-table__expand-column .cell {
  display: none;
}
.el-table .warning-row {
  background: oldlace;
}

.el-table .success-row {
  background: #f0f9eb;
}
</style>
<style lang="scss" scoped>
.el-select-dropdown__item.selected {
  font-weight: 500;
  color: #606266;
}
// .el-select-dropdown__item.hover,
// .el-select-dropdown__item:hover {
//   font-weight: 200;
// }
// .el-table tbody tr:hover > td {
//   background-color: #f5f5f5 !important; //修改成自己想要的颜色即可
// }

.backMenu {
  width: 100%;
  // .box-card {
  //   margin-top: 10px;
  // }
  .MenuTop {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    .btn {
      margin-left: 20px;
    }
  }
  .MenuList {
    margin-top: 20px;
    width: 100%;
    // max-height: 65vh;
    .exceed {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }
    .rootBtn {
      button {
        border: none;
        background: none;
        cursor: pointer;
        color: #06b7ae;
      }
    }
  }
  .paging {
    margin-top: 20px;
    display: flex;
    justify-content: right;
  }
  .AddMenu {
    width: 100%;
    max-height: 500px;
    .AddMenu-1-box {
      display: flex;
      justify-content: center;
      .inputbox {
        width: 400px;
      }
      .more {
        display: flex;
        margin-bottom: 22px;
        > div {
          min-width: 100px;
          span {
            display: inline-block;
            width: 40px;
            text-align: center;
            margin-left: 10px;
            font-size: 14px;
            // color: #06b7ae;
            cursor: pointer;
          }
        }
        input {
          flex: 1;
        }
      }
      .nomb {
        margin-bottom: 0;
      }
    }
  }
}
</style>
